<template>
  <div class="wrap">
    <!-- 一键三连-->
    <div class="item" @click="onFavorite">
      <svg-icon
        icon="icon_fav"
        :class="`${favorited ? 'light' : ''}`"
      ></svg-icon
      ><a>{{ favorited ? '已收藏' : '收藏' }}</a>
    </div>
    <div class="item">
      <svg-icon icon="icon_comment"></svg-icon><a href="#comment">评论</a>
    </div>
    <div class="item"><svg-icon icon="icon_share"></svg-icon><a>转发</a></div>
  </div>
</template>
<script setup>
import { getCurrentInstance, defineProps, computed, ref, onMounted,watch } from 'vue'
import { showLogin } from '@/libs/comp'
import qs from 'qs'
import { Api } from '@/services/http'

const instance = getCurrentInstance()
const favorited = ref(false)
const props = defineProps({
  targetId: {
    type: String,
    required: true,
  },
  favoritesType: {
    type: String,
    required: true,
  },
  favorited: {
    type: Boolean,
  },
})

watch( props ,(newProps)=>{
  if( newProps.favorited){
    favorited.value = true
  }
})
const onFavorite = async () => {
  const { proxy, ctx } = instance
  const payload = qs.stringify({
    targetId: props.targetId,
    favoritesType: props.favoritesType,
  })
  try {
    await Api.post(`/client-api/favorites/save`, payload)
    favorited.value = !favorited.value
    console.log(favorited.value)
  } catch (err) {}

  //showLogin(proxy)
}
onMounted(() => {
  if (props.favorited) {
    favorited.value = true
  }
})
</script>
<style lang="scss" scoped>
.wrap {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: space-around;
  .item {
    display: flex;
    justify-items: center;
    color: #666;
    &:hover {
      color: #237ece;
    }
  }
  .svg-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .light {
    color: red;
  }
}
</style>
