<template>
  <div class="comment">
    <a name="comment"></a>
    <div class="items">
      <div class="item" v-for="(item, index) in pageData.list" :key="item.id">
        <div class="avator">
          <svg-icon icon="icon_avator"></svg-icon>
          <span>
            <div>{{ item.userNickname }}</div>
            <div style="height: 14px; overflow: hidden">
              <el-rate :value="item.userStarLevel || 0" disabled></el-rate>
            </div>
            <div style="font-size: 12px; color: #ccc">
              来自：{{ item.comeFrom }}
            </div>
            <div style="font-size: 12px; color: #ccc">创建于:{{ item.createDate  }}</div>
          </span>
        </div>
        <div class="comment">
          <div class="content">{{ item.comment }}</div>
        </div>
      </div>
      <div class="">
        <h4 style="margin-bottom: 0; padding-bottom: 0">发布评论</h4>
        <div class="comment">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="pageData.comment"
          >
          </el-input>
        </div>
        <div class="send" style="text-align: right; margin-top: 10px">
          <el-button
            type="primary"
            :disabled="!pageData.comment"
            @click="handleComment"
            >发布评论</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, computed, defineProps, watch } from 'vue'
import { useRoute } from 'vue-router'
import qs from 'qs'
import { Options, Vue } from 'vue-class-component'
import { Api } from '@/services/http'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  type: {
    required: false,
    default: 1,
  },
})
const pageData = reactive({
  list: [],
  comment: '',
})
const pagination = reactive({
  current: 1,
  size: 15,
})
watch(props, ()=>{
  load()
})
const handleComment = async () => {
  const data = await Api.post(`/client-api/comment/save`, qs.stringify({
    commentType:props.type,
    targetId:props.id,
    comment:pageData.comment
  }))
  pageData.comment = ''
  await load();
}
const load = async () => {
  const query = qs.stringify(pagination)
  const com = await Api.get(
    `/client-api/comment/list?targetId=${props.id}&commentType=${props.type}&${query}`
  )
  pageData.list = com.list
}
const mounted = async () => {
  await load()
}
mounted()
</script>
<style lang="scss" scoped>
.comment {
  margin: 0;
  width: 100%;
  font-size: 14px;
  position: relative;
  .item {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }
  .content {
    border: 1px solid #ededed;
    padding: 10px;
    border-radius: 5px;
    background: white;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    position: relative;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      border-top: 5px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 11px solid #dedede;
      height: 0px;
      left: -15px;
      top: 5px;
    }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      border-top: 5px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 11px solid white;
      height: 0px;
      left: -14px;
      top: 5px;
    }
  }
}
.avator {
  display: flex;
  width: 200px;
  flex-basis: 240px;
  .svg-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  ::v-deep .el-rate {
    height: 14px;
    .el-rate__icon {
      margin-right: 0;
      margin-left: 1px;
    }
    .el-icon {
      width: 12px;
    }
    .el-icon svg {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
